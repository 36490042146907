import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { Col, Row, Divider, Tag, Spin, Empty } from "antd";
import BlogPreview from "./blogPreview";
import {
  collection,
  onSnapshot,
  orderBy,
  query,
  limit,
  where,
} from "firebase/firestore";
import { db } from "../../firebaseConfig";

// const { Search } = Input;

function Blogs() {
  const { keyword } = useParams();
  const [loading, setLoading] = useState(true);
  const [articles, setArticles] = useState([]);
  // const [searchText, setSearchText] = useState("")
  // const onSearch = (value, _e, info) => setSearchText(value);

  useEffect(() => {
    const articleRef = collection(db, "Articles");
    let q = query(articleRef, orderBy("createdAt", "desc"), limit(15));
    if (keyword)
      q = query(
        articleRef,
        where("tags", "array-contains", keyword),
        orderBy("createdAt", "desc"),
        limit(15)
      );
    // if (searchText) q = query(articleRef, where("title", ">=", searchText), orderBy("title", "desc"), limit(15));
    onSnapshot(q, (snapshot) => {
      const articles = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setArticles(articles);
      setLoading(false);
    });
  }, [keyword]);

  return (
    <div className="container">
      <div className="heroBlock">
        {loading ? (
          <Spin />
        ) : articles && articles.length > 0 ? (
          <Row gutter={[24, 24]}>
            {/* carousel */}
            <Col xs={24} lg={18}>
              {/* <Carousel autoplay>
            {console.log(articles.slice(0, 5))}
            {articles.slice(0, 5).map((article) => (
              <div>
                <img
                  style={contentStyle}
                  src={article.imageUrl}
                  alt={article.title}
                />
              </div>
            ))}
          </Carousel> */}
              <span>
                <Divider orientation="left" orientationMargin="0">
                  Latest Articles
                </Divider>
                {articles.map((article) => (
                  <BlogPreview key={article.id} article={article} />
                ))}
              </span>
            </Col>
            {/* hero blocks */}
            <Col xs={24} lg={6}>
              <div className="heroBlocks">
                {/* <span>
                <Divider orientation="left" orientationMargin="0">
                  Search
                </Divider>
                <Search
                  name="keyword"
                  placeholder="input search text"
                  onSearch={onSearch}
                  enterButton
                />
              </span> */}
                <span>
                  <Divider orientation="left" orientationMargin="0">
                    Categories
                  </Divider>
                  {[...new Set(articles.flatMap(({ tags }) => tags))].map(
                    (tag) => (
                      <Tag key={tag}>
                        <Link to={`/blogs/${tag}`}>#{tag}</Link>
                      </Tag>
                    )
                  )}
                </span>
              </div>
            </Col>
          </Row>
        ) : (
          <Empty
            image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
            imageStyle={{
              height: 120,
            }}
            description={<span>No blogs found</span>}
          ></Empty>
        )}
      </div>
    </div>
  );
}

export default Blogs;
