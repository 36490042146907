import React from 'react'
import { Spin } from 'antd'
import useAuthentication from '../../hooks/useAuthentication'
import { Outlet, Navigate } from 'react-router-dom'

const PrivateRoute = (props) => {
  
  const { isLogged, checkingStatus } = useAuthentication()  
    
  if(checkingStatus) {
      return <Spin />
  }
  return isLogged ? <Outlet /> : <Navigate to={props.route} />
}

export default PrivateRoute