import React from "react";
import { doc, onSnapshot } from "firebase/firestore";
import { auth, db } from "../firebaseConfig";
import { useAppSelector } from "./redux-hooks";

export const useMessages = () => {
  const [messages, setMessages] =
    (React.useState([]) );
  const chatID = useAppSelector((state) => state.currentChat.chatID);

  React.useEffect(() => {
    async function getMessages() {
      if (auth.currentUser?.uid) {
        onSnapshot(doc(db, "chats", chatID), (doc) => {
          doc.exists() && setMessages(doc.data());
        });
      }
    }
    getMessages();
  }, [chatID, setMessages]);

  return messages.messages;
};
