import React from 'react';
import { Row, Col } from 'antd';
import { SecurityScanOutlined,  DesktopOutlined, DatabaseOutlined } from "@ant-design/icons";


const items = [
  {
    key: '1',
    icon: <DesktopOutlined />,
    title: 'A Platform for Brahmins, by Brahmins',
    content: 'We take pride in being a community-driven platform, created by individuals who understand the nuances of Brahmin traditions. Our founders and team members share a common commitment to preserving the essence of Brahmin culture while providing a modern and efficient way for Brahmin singles to meet their life partners',
  },
  {
    key: '2',
    icon: <SecurityScanOutlined />,
    title: 'Privacy and Security',
    content: 'We understand the importance of privacy and security in the context of matrimonial searches. Brahminmatches employs robust security measures to safeguard your personal information, ensuring a safe and confidential space for your journey to find a life partner.',
  },
  {
    key: '3',
    icon: <DatabaseOutlined />,
    title: 'Diverse Profiles, Shared Values',
    content: "Our platform hosts a diverse array of profiles within the Brahmin community, allowing you to explore a wide range of potential matches while staying rooted in shared cultural values. Whether you're looking for a partner from the same subcaste or open to inter-subcaste matches, Brahminmatches provides a space for diverse preferences."
  }
]

function AppAbout() {
  return (
    <>
     <div id="about" className="block aboutBlock">
      <div className="container-fluid">
        <div className="titleHolder">
          <h2>About Us</h2>
        </div>
        <div className="contentHolder">
          <p>
          Welcome to Brahminmatches, a sacred space designed exclusively for Brahmin communities to embark on the 
          beautiful journey of finding a life partner. We understand the sanctity and significance of marriage in Brahmin culture, 
          and our platform is crafted with utmost care to honor these traditions. </p>

          <p>
          At Brahminmatches, we celebrate the rich cultural heritage of Brahmins, acknowledging the values, traditions, and principles 
          that make Brahmin communities unique. Established with a deep respect for the sanctity of marriage, our platform is committed 
          to fostering meaningful connections within the Brahmin community.,
          </p>

          <p>Brahminmatches goes beyond the conventional approach to matchmaking. Our platform combines traditional values with 
          contemporary technology to facilitate meaningful connections. We recognize that compatibility goes beyond surface-level criteria, 
          and our advanced algorithms are designed to understand the deeper aspects of compatibility unique to Brahmin communities.
          </p>
        </div>
        <Row gutter={[16, 16]}>   
          {items.map(item => {
            return (
              <Col md={{ span: 8 }} key={item.key}>
                <div className="content">
                  <div className="icon">
                    {item.icon}
                  </div>
                  <h3>{item.title}</h3>
                  <p>{item.content}</p>
                </div>
              </Col>
            );
          })}
        </Row>
        <p>
        We invite you to join our community of like-minded Brahmin individuals seeking a life partner. Brahminmatches is more than just a platform; it's a community 
        that understands the sacred nature of marriage within Brahmin culture.
        </p>
        <p>
        Embark on your journey with Brahminmatches, where tradition meets technology, and where meaningful connections are nurtured with care and respect.
        </p>
      </div>
    </div>  
    </>
   
  );
}

export default AppAbout;