  import React from "react";
  import { Link } from "react-router-dom";
  // import { auth } from "../../firebaseConfig";
  import { Card, Badge, Spin, Typography } from "antd";
  import { getAge } from "../../lib/utils";
  import { useUserData } from "../../hooks/useUsers";
  
  const { Meta } = Card;
  
  export default function Match({ userId }) {
    // const [user] = useAuthState(auth);
    const { loading, userData } = useUserData(userId);
    return (
      loading ? (
        <Spin />
      ) : userData && (<>
            <Link to={`/profile-page/${userData.uid}`}>
              <Badge.Ribbon
                text={`${getAge(userData.dateOfBirth)} yrs`}
                color="blue"
              >
                <Card
                  hoverable
                  cover={
                    <div style={{ overflow: "hidden", height: "240px" }}>
                      <img
                        alt={userData.firstName}
                        style={{ height: "100%", width: "100%" }}
                        src={userData?.profileImages[0].url}
                      />
                    </div>
                  }
                >
                  <Meta title={`${userData.firstName} ${userData.lastName}`} />
                  <Typography>
                    {userData.gender} | {userData.maritalStatus} |{" "}
                    {userData.subCaste} | {userData.education} |{" "}
                    {userData.currentResidence}
                  </Typography>
                </Card>
              </Badge.Ribbon>
            </Link>
          </>
        )
    );
  }
  