import React, { useState } from "react";
import { auth, db } from "../../firebaseConfig";
import { useNavigate } from "react-router-dom";
import { Row, Col } from "antd";
import { Empty } from "antd";
import { useAuthState } from "react-firebase-hooks/auth";
import { useUserData } from "../../hooks/useUsers";
import Interest from "./Interest";
import { Tabs, Spin } from "antd";
import Match from "./Match";
import {
  collection,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";

export default function Interests() {
  let navigate = useNavigate();
  const [spinner, setSpinner] = useState(true);
  const [user] = useAuthState(auth);
  const { loading, userData } = useUserData(user?.uid);
  const [profiles, setProfiles] = useState([]);

  React.useEffect( () => {
    const profileRef = collection(db, "users");    
    let q = query(
      profileRef,
      where("friendRequests", "array-contains", user?.uid)
    );

    onSnapshot(q, (snapshot) => {
      const profiles = snapshot.docs.map((doc) => ({
        ...doc.data(),
      }));
      setProfiles(profiles);
      setSpinner(false);
    });

  }, [setProfiles, user.uid])

  React.useEffect(() => {
    if (loading) return;
    if (userData && !userData.profileCompleted) {
      navigate('/add-profile');
    } else {
      return
    }
  }, [userData, loading, navigate]);

  function matches() {
    return loading ? (
      <Spin />
    ) : userData?.friends?.length > 0 ? (
      <div id="feature" className="block featureBlock bgGray">
        <div className="container">
          <Row gutter={[16, 16]}>
            {userData?.friends.map((userId) => {
              return (
                <Col
                  key={userId}
                  xs={{ span: 24 }}
                  sm={{ span: 12 }}
                  md={{ span: 8 }}
                  lg={{ span: 6 }}
                >
                  <Match userId={userId} />
                </Col>
              );
            })}
          </Row>
        </div>
      </div>
    ) : (
      <Empty
        image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
        imageStyle={{
          height: 120,
        }}
        description={<span>No interests accepted.</span>}
      ></Empty>
    );
  }

  function interestsReceived() {
    return loading ? (
      <Spin />
    ) : userData?.friendRequests?.length > 0 ? (
      <div id="feature" className="block featureBlock bgGray">
        <div className="container">
          <Row gutter={[16, 16]}>
            {userData?.friendRequests.map((userId) => {
              return (
                <Col
                  key={userId}
                  xs={{ span: 24 }}
                  sm={{ span: 12 }}
                  md={{ span: 8 }}
                  lg={{ span: 6 }}
                >
                  <Interest disableButtons={false} userId={userId} />
                </Col>
              );
            })}
          </Row>
        </div>
      </div>
    ) : (
      <Empty
        image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
        imageStyle={{
          height: 120,
        }}
        description={<span>No interests received.</span>}
      ></Empty>
    );
  }

  function interestSent() {
    return spinner ? (
      <Spin />
    ) : profiles?.length > 0 ? (
      <div id="feature" className="block featureBlock bgGray">
        <div className="container">
          <Row gutter={[16, 16]}>
            {profiles?.map((profile) => {
              return (
                <Col
                  key={profile.profileId}
                  xs={{ span: 24 }}
                  sm={{ span: 12 }}
                  md={{ span: 8 }}
                  lg={{ span: 6 }}
                >
                  <Interest disableButtons={true} userId={profile.uid} />
                </Col>
              );
            })}
          </Row>
        </div>
      </div>
    ) : (
      <Empty
        image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
        imageStyle={{
          height: 120,
        }}
        description={<span>No interests received.</span>}
      ></Empty>
    );
  }

  const tabItems = [
    {
      key: "1",
      label: <h2>Interest Accepted</h2>,
      children: matches(),
    },
    {
      key: "2",
      label: <h2>Interests Recevied</h2>,
      children: interestsReceived(),
    },
    {
      key: "3",
      label: <h2>Interests Sent</h2>,
      children: interestSent(),
    },
  ];

  return (
    <Spin spinning={loading}>
      <div id="feature" className="block featureBlock bgGray">
        <div className="container">
          <Tabs defaultActiveKey="1" items={tabItems} />
        </div>
      </div>
    </Spin>
  );
}
