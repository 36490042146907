import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  username: null,
  email: null,
  token: null,
  id: null,
}

const authUser = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setAuthUser(state, action) {
      state.username = action.payload.username
      state.email = action.payload.email;
      state.token = action.payload.token;
      state.id = action.payload.id;
    },
    removeAuthUser(state) {
      state.username = null;
      state.email = null;
      state.token = null;
      state.id = null;
    }
  }
})

export const { setAuthUser, removeAuthUser } = authUser.actions

export default authUser.reducer; 