import React from 'react';
import { auth } from '../../firebaseConfig';
import { useAuthState } from "react-firebase-hooks/auth";
import { useUserData } from '../../hooks/useUsers';
import { onAcceptFriend } from "../../hooks/onAcceptFriend";
import { onDeclineFriendRequest } from "../../hooks/onDeclineFriendRequest";
import { Button } from 'antd';
import { CheckOutlined, CloseCircleOutlined } from "@ant-design/icons";


function Request({ id }) {
    const [user] = useAuthState(auth);
    const { userData } = useUserData(id);

  async function handleAccept() {
    await onAcceptFriend(user.uid, id);
  }

  function handleDecline() {
    onDeclineFriendRequest(id, user.uid);
  }
  return (
    <div>
        <div>
          <h4>
            {userData?.firstName + ' ' + userData?.lastName}{' '}
            <span>is interested in your profile</span>
          </h4>
        </div>
          <Button onClick={handleAccept}>
            <CheckOutlined /> Accept
          </Button>
          <Button danger onClick={handleDecline}>
            <CloseCircleOutlined /> Decline
          </Button>
    </div>
  );
};

export default Request;
