import React from 'react';
import { Empty } from 'antd';
import { Link } from 'react-router-dom';

function NoData() {
    return (
        <div>
            <Empty
                image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                imageStyle={{
                    height: 240,
                }}
                description={
                    <span>
                        Page Not found
                    </span>
                }
            >
                <Link to="/">Go back to Home</Link>
            </Empty>
        </div>
    )
}

export default NoData
