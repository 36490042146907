import React from "react";
import { Link, useNavigate } from "react-router-dom";
// import { auth } from "../../firebaseConfig";
import { Typography } from "antd";
import { Card, Badge, Spin } from "antd";
// import { useAuthState } from "react-firebase-hooks/auth";
import { getAge } from "../../lib/utils";
import { useUserData } from "../../hooks/useUsers";
import Request from "./Request";

const { Meta } = Card;

export default function Interest({ disableButtons, userId }) {
  // const [user] = useAuthState(auth);
  let navigate = useNavigate();
  const { loading, userData } = useUserData(userId);

  React.useEffect(() => {
    if (loading) return;
    if (userData && !userData.profileCompleted) {
      navigate('/add-profile');
    } else {
      return
    }
  }, [userData, loading, navigate]);

  return (
    loading ? (
      <Spin />
    ) : userData && (<>
      <Link to={`/profile-page/${userData?.uid}`}>
        <Badge.Ribbon
          text={`${getAge(userData?.dateOfBirth)} yrs`}
          color="blue"
        >
          <Card
            hoverable
            cover={
              <div style={{ overflow: "hidden", height: "240px" }}>
                <img
                  alt={userData?.firstName}
                  style={{ height: "100%", width: "100%" }}
                  src={userData?.profileImages[0].url}
                />
              </div>
            }
          >
            <Meta title={`${userData?.firstName} ${userData?.lastName}`} />
            <Typography>
              {userData?.gender} | {userData?.maritalStatus} |{" "}
              {userData?.subCaste} | {userData?.education} |{" "}
              {userData?.currentResidence}
            </Typography>
          </Card>
        </Badge.Ribbon>
      </Link>
      {!disableButtons ? 
      <Request key={userId} id={userId} /> : null}
    </>)
  );
}
