import React from "react";
// import { format } from 'date-fns';
import { Avatar } from "antd";
import { auth } from "../../../../firebaseConfig";
import { MessageBox } from "react-chat-elements";

const Message = ({ message }) => {
  const ref = React.useRef(null);

  React.useEffect(() => {
    if (ref) {
      ref.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [message]);

  return (
    <div
      style={{
        color: message.senderID === auth.currentUser?.uid ? "red" : "blue",
      }}
    >
      <MessageBox
        position={message.senderID === auth.currentUser?.uid ? "right" : "left"}
        title={
          <>
            <Avatar src={message.avatarURL} alt={message.displayName} />{" "}
            {message.displayName}
          </>
        }
        type="text"
        text={message.text}
        date={message.date}
        replyButton={false}
      />
      {/* <Comment
        style={{
          flexDirection: message.senderID === auth.currentUser?.uid ? 'row-reverse' : undefined,
        }}
        author="user"
        avatar={
          <Avatar
            src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png"
            alt="Han Solo"
          />
        }
        content={
          <p style={{
            color: message.senderID === auth.currentUser?.uid ? "red" : "blue"
          }}>
            {message.image && <img src={message.image} alt="Message" height="50px" width="50px" />}
            {message.text}
          </p>
        }
        datetime={
          <Tooltip title={format(message.date, 'HH:mm')}>
            <span>{format(message.date, 'HH:mm')}</span>
          </Tooltip>
        }
      /> */}
      {/* <div
      ref={ref}
      className={s.message}
      style={{
        flexDirection: message.senderID === auth.currentUser?.uid ? 'row-reverse' : undefined,
      }}>
      <div className={s.avatar}>
        <Avatar id={message.senderID} />
      </div>
      <div className={s.content}>
        {message.image && <img src={message.image} alt="Message" height="50px" width="50px" />}
        <div className={s.details}>
          <span className={s.text}>{message.text}</span>
          <span className={s.date}>{format(message.date, 'HH:mm')}</span>
        </div>
      </div>
    </div> */}
    </div>
  );
};

export default Message;
