import { createSlice } from '@reduxjs/toolkit';
import { auth } from '../../firebaseConfig';


const initialState = {
  userID: null,
  chatID: null
}

const currentChat = createSlice({
  name: 'currentChat',
  initialState,
  reducers: {
    setCurrentChat(state, action) {
      state.userID = action.payload;
      state.chatID = auth.currentUser?.uid > state.userID ? auth.currentUser?.uid + state.userID : state.userID + auth.currentUser?.uid
    },
  }
})

export const { setCurrentChat } = currentChat.actions

export default currentChat.reducer; 