import React, { useState, useEffect, useRef } from "react";
import {
  signInWithEmailAndPassword,
  onAuthStateChanged,
  applyActionCode,
} from "firebase/auth";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db } from "../../firebaseConfig";
import { doc, getDoc } from "firebase/firestore";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Row, Col } from "antd";
import "./auth.css";
import {
  Spin,
  Flex,
  Card,
  Form,
  Input,
  Button,
  message,
  Typography,
} from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import OAuth from "./OAuth";

export default function AdminLogin() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const mode = queryParams.get("mode");
  const apiKey = queryParams.get("apiKey");
  const actionCode = queryParams.get("oobCode");

  useEffect(() => {
    if (
      mode === "verifyEmail" &&
      apiKey === process.env.REACT_APP_API_FIREBASE_KEY
    ) {
      applyActionCode(auth, actionCode)
        .then((resp) => {
          message.success("You email has been verified. Please login");
        })
        .catch((error) => {
          if (error === "auth/invalid-action-code") {
            message.error("Email link to verify is invalid.");
          } else {
            message.error("Unknown error verifying email");
          }
        });
    } else {
      return;
    }
  }, [actionCode, apiKey, mode]);

  const [user] = useAuthState(auth);
  let navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const isMounted = useRef(true);

  const { Title } = Typography;

  useEffect(() => {
    if (isMounted) {
      onAuthStateChanged(auth, (user) => {
        if (user) {
          <Spin />;
          navigate("/");
        }
      });
    }

    return () => {
      isMounted.current = false;
    };
  }, [navigate, isMounted]);

  const handleLogin = async () => {
    try {
      const result = await signInWithEmailAndPassword(auth, email, password);
      const docRef = doc(db, "users", result.user.uid);
      const docSnap = await getDoc(docRef);
      if (docSnap.data().profileCompleted) {
        message.success("Welcome back!");
        navigate("/");
      } else {
        message.success("Great!. Lets create a profile!");
        navigate("/add-profile");
      }
    } catch (error) {
      message.error("Invalid Email and/or Password");
    }
  };

  return (
    <div className="container">
      {user ? (
        <>{navigate("/")}</>
      ) : (
        <Row
          gutter={[16, 16]}
          type="flex"
          justify="center"
          align="middle"
          style={{ minHeight: "60vh", marginBottom: 8 }}
        >
          <Col className="gutter-row" xs={24} lg={16} xl={10}>
            <Card
              justify="center"
              align="middle"
              style={{
                width: 400,
                margin: "20px",
                borderRadius: "20px",
                overflow: "hidden",
                boxShadow: "5px 8px 24px 5px rgba(208, 216, 243, 0.6)",
              }}
            >
              <Form
                className="login-form"
                autoComplete="off"
                layout="vertical"
                onFinish={handleLogin}
              >
                <Flex
                  type="flex"
                  justify="center"
                  align="middle"
                  gap="small"
                  wrap="center"
                >
                  <Title level={3}>Login</Title>
                </Flex>
                <Flex
                  type="flex"
                  justify="center"
                  align="middle"
                  gap="small"
                  wrap="center"
                >
                  <Link to="/register">Don't have an Account</Link> /
                  <Link to="/forgot-password">Forgot Password</Link>
                </Flex>
                <br />
                <Form.Item
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "Please input Email Address",
                    },
                  ]}
                >
                  <Input
                    prefix={<UserOutlined />}
                    placeholder="Email address"
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                  />
                </Form.Item>
                <Form.Item
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Please input Password",
                    },
                  ]}
                >
                  <Input
                    prefix={<LockOutlined />}
                    type="password"
                    placeholder="Password"
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                  />
                </Form.Item>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="login-form-button"
                  >
                    Log in
                  </Button>
                </Form.Item>
              </Form>
              <OAuth />
            </Card>
          </Col>
        </Row>
      )}
    </div>
  );
}
