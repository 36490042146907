import React from "react";
import { Form, Input, Space, Button } from "antd";
import { SendOutlined } from "@ant-design/icons";
// import { faCheck, faImage, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import s from "../../Messenger.module.scss";
import { onMessageSend } from "../../../../hooks/onMessageSend";
import { useAppSelector } from "../../../../hooks/redux-hooks";
import {
  RegExpMatcher,
  englishDataset,
  englishRecommendedTransformers,
} from "obscenity";

const matcher = new RegExpMatcher({
  ...englishDataset.build(),
  ...englishRecommendedTransformers,
});

const WriteMessage = () => {
  const currentChat = useAppSelector((state) => state.currentChat);
  const [message, setMessage] = React.useState({
    text: "",
    image: "",
  });

  return (
    <div className={s.input}>
      <Space.Compact
        style={{
          width: "100%",
        }}
      >
        <Form.Item
          style={{
            width: "100%",
          }}
          validateStatus={
            matcher.hasMatch(message.text)
              ? "error"
              // : message.text === ""
              // ? "error"
              : Boolean(message.text.length > 108)
              ? "error"
              : "success"
          }
          help={
            matcher.hasMatch(message.text)
              ? "The input text has profanities"
              // : message.text === ""
              // ? "Please enter some text"
              : Boolean(message.text.length > 108)
              ? "Message length cannot exceed 108 characters"
              : ""
          }
        >
          <Input
            value={message.text}
            placeholder="Write something"
            onChange={(e) => {
              setMessage({ ...message, text: e.target.value });
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                if (message.image !== "" || message.text !== "") {
                  onMessageSend(
                    message,
                    currentChat.userID,
                    currentChat.chatID
                  );
                  setMessage({
                    text: "",
                    image: "",
                  });
                } else {
                  alert("You must fill all fields.");
                }
              }
            }}
          />
        </Form.Item>
        <Button
          disabled={message.text === "" || matcher.hasMatch(message.text) || Boolean(message.text.length > 108)}
          onClick={() => {
            if (message.image !== "" || message.text !== "") {
              onMessageSend(message, currentChat.userID, currentChat.chatID);
              setMessage({
                text: "",
                image: "",
              });
            } 
          }}
        >
          <SendOutlined />
        </Button>
      </Space.Compact>
      {/* <label htmlFor="file" style={{ backgroundColor: message.image ? '#0954b6' : '' }}>
        <FontAwesomeIcon icon={message.image ? faCheck : faImage} />
        <input
          id="file"
          type="file"
          onChange={(e) => setMessage({ ...message, image: e.target.files[0] })}
          hidden
        />
      </label> */}
    </div>
  );
};

export default WriteMessage;
