import React, { useState, useEffect } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useParams, useNavigate } from "react-router-dom";
import { auth } from "../../firebaseConfig";
import LikeProfile from "./LikeProfile";
import { useUserData } from "../../hooks/useUsers";
import { Empty } from "antd";
import {
  Row,
  Col,
  Card,
  Button,
  QRCode,
  Typography,
  Image,
  Modal,
  Table,
  Space,
  Spin,
  Badge,
} from "antd";
import { getGunaPoints } from "../../lib/utils";

const { Title } = Typography;
const { Meta } = Card;

function Profile() {
  const { id } = useParams();
  const [showMore, setShowMore] = useState(false);
  const [profile, setProfile] = useState();
  const [user] = useAuthState(auth);
  const { loading: currentUserLoading, userData: currentUser } = useUserData(
    user?.uid
  );
  const { loading, userData } = useUserData(id);
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const currentHost = `${window.location.protocol}//${window.location.hostname}:${window.location.port}`;

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    if (loading) return;
    if (currentUserLoading) return;

    if (userData) {
      if (!currentUser?.profileCompleted) {
        navigate("/add-profile");
      } else {
        setProfile(userData);
      }
    } else {
      return;
    }
  }, [user, userData, loading, currentUser, currentUserLoading, navigate]);

  // remove blank keys from profile..
  // profile &&
  //   Object.keys(profile).forEach((k) => ( profile[k] === "" || profile[k] === undefined) && delete profile[k]);

  // const contactColumns = {
  //   contactName: "Contact Name",
  //   contactNumber: "Contact #",
  // };

  const basicColumns = {
    username: "UserName",
    firstName: "First Name",
    lastName: "Last Name",
    gender: "Gender",
    maritalStatus: "Marital Status",
    dateOfBirth: "Date of Birth",
    timeOfBirth: "Time of Birth",
    placeOfBirth: "Place of Birth",
  };

  const horoscopeColumns = {
    subCaste: "Sub Caste",
    gothram: "Gothram",
    nakshatram: "Nakshatram",
    padam: "Padam",
    raasi: "Raasi",
  };

  const educationColumns = {
    education: "Education",
    employment: "Employment",
    salary: "Salary",
    salaryType: "Salary Type",
  };

  const familyColumns = {
    fathersOccupation: "Fathers Occupation",
    fathersName: "Fathers Name",
    mothersOccupation: "Mothers Occupation",
    mothersName: "Mothers Name",
    brothers: "Brothers",
    brothersMarital: "Brothers Marital",
    sisters: "Sisters",
    sistersMarital: "Sisters Marital",
    familyValues: "Family Values",
    familyType: "Family Type",
    familyStatus: "Family Status",
    nativePlace: "Native Place",
  };

  const titles = [
    {
      title: "Title",
      dataIndex: "title",
    },
    {
      title: "Value",
      dataIndex: "value",
    },
  ];

  // const contactData = profile.contact?.map((item) => {
  //   if (!item.private) {
  //     return {
  //       key: item.contactName,
  //       title: item.contactName,
  //       value: item.contactNumber,
  //     };
  //   }
  //   return null;
  // });

  const basicData = Object.keys(basicColumns)
    .map((basic) => ({
      key: basic,
      title: basicColumns[basic],
      value:
        basic === "dateOfBirth"
          ? profile && profile[basic]?.toDate().toDateString()
          : profile && profile[basic],
    }))
    .filter(({ key, title, value }) => key && title && value);

  const horoscopeData = Object.keys(horoscopeColumns)
    .map((horoscope) => ({
      key: horoscope,
      title: horoscopeColumns[horoscope],
      value: profile && profile[horoscope],
    }))
    .filter(({ key, title, value }) => key && title && value);

  const educationData = Object.keys(educationColumns)
    .map((education) => ({
      key: education,
      title: educationColumns[education],
      value: profile && profile[education],
    }))
    .filter(({ key, title, value }) => key && title && value);

  const familyData = Object.keys(familyColumns)
    .map((family) => ({
      key: family,
      title: familyColumns[family],
      value: profile && profile[family],
    }))
    .filter(({ key, title, value }) => key && title && value);

  return (
    <div className="container">
      <Spin spinning={loading}>
        {profile ? (
          <Row>
            <Col xs={24}>
              <Card bordered={false}>
                <Button size="small" onClick={() => navigate(-1)}>
                  Go Back
                </Button>
                <br />
                <Row>
                  <Col sm={10} md={10} xl={10} style={{ padding: "20px" }}>
                    <Image.PreviewGroup
                      items={profile?.profileImages.map((image) => image.url)}
                    >
                      <Image
                        width={300}
                        height={300}
                        src={
                          profile?.profileImages &&
                          profile?.profileImages[0]?.url
                        }
                      />
                    </Image.PreviewGroup>
                    <Title level={4}>
                      {profile.firstName} {profile.lastName}
                    </Title>
                    <Badge.Ribbon
                      placement="start"
                      text={
                        <span>
                          Guna Points:{" "}
                          {getGunaPoints(
                            currentUser?.gender === "Female"
                              ? currentUser?.nakshatram +
                                  "|" +
                                  currentUser?.padam +
                                  "|" +
                                  profile?.nakshatram +
                                  "|" +
                                  profile?.padam
                              : profile?.nakshatram +
                                  "|" +
                                  profile?.padam +
                                  "|" +
                                  currentUser?.nakshatram +
                                  "|" +
                                  currentUser?.padam
                          )}
                        </span>
                      }
                    >
                      <Card hoverable style={{ width: 300 }}>
                        <Meta
                          title={<span> </span>}
                          description={
                            <div className="additional">
                              <p style={{ margin: 0 }}>
                                My Nakshatram:{" "}
                                <span
                                  style={{
                                    color:
                                      currentUser?.gender === "Male"
                                        ? "blue"
                                        : "red",
                                  }}
                                >
                                  {currentUser?.nakshatram}
                                </span>
                              </p>
                              {currentUser?.padam > 0 ? (
                                <p style={{ margin: 0 }}>
                                  My Padam:{" "}
                                  <span
                                    style={{
                                      color:
                                        currentUser?.gender === "Male"
                                          ? "blue"
                                          : "red",
                                    }}
                                  >
                                    {currentUser?.padam}
                                  </span>
                                </p>
                              ) : null}
                              <p style={{ margin: 0 }}>
                                Their Nakshatram:{" "}
                                <span
                                  style={{
                                    color:
                                      profile?.gender === "Male"
                                        ? "blue"
                                        : "red",
                                  }}
                                >
                                  {profile?.nakshatram}
                                </span>
                              </p>
                              {profile?.padam !== 0 ? (
                                <p style={{ margin: 0 }}>
                                  Their Padam:{" "}
                                  <span
                                    style={{
                                      color:
                                        profile?.gender === "Male"
                                          ? "blue"
                                          : "red",
                                    }}
                                  >
                                    {profile?.padam}
                                  </span>
                                </p>
                              ) : null}
                            </div>
                          }
                        />
                      </Card>
                    </Badge.Ribbon>
                  </Col>
                  <Col sm={14} md={14} xl={14}>
                    <Space style={{ marginBottom: "10px" }}>
                      {user && profile.uid && (
                        <LikeProfile
                          id={profile.uid}
                          userId={currentUser.uid}
                        />
                      )}
                      <Button type="primary" onClick={showModal}>
                        {" "}
                        QR Code
                      </Button>
                    </Space>
                    <Modal
                      centered
                      width={300}
                      title="Profile Page"
                      open={isModalOpen}
                      onOk={handleOk}
                      onCancel={handleCancel}
                    >
                      <QRCode
                        size={240}
                        type="canvas"
                        value={`${currentHost}/profile-page/${profile.id}`}
                      />
                    </Modal>
                    <Card title={<Title level={5}>Basic Information</Title>}>
                      <Table
                        size="small"
                        columns={titles}
                        dataSource={basicData}
                        pagination={false}
                        showHeader={false}
                      />
                    </Card>
                    <Card
                      title={
                        <Title level={5}>
                          Ecutation, Employment Information
                        </Title>
                      }
                    >
                      <Table
                        size="small"
                        columns={titles}
                        dataSource={educationData}
                        pagination={false}
                        showHeader={false}
                      />
                    </Card>
                    {showMore ? (
                      <>
                        <Card
                          title={<Title level={5}>Horoscope Information</Title>}
                        >
                          <Table
                            size="small"
                            columns={titles}
                            dataSource={horoscopeData}
                            pagination={false}
                            showHeader={false}
                          />
                        </Card>
                        <Card
                          title={<Title level={5}>Family Information</Title>}
                        >
                          <Table
                            size="small"
                            columns={titles}
                            dataSource={familyData}
                            pagination={false}
                            showHeader={false}
                          />
                        </Card>
                        <Card title={<Title level={5}>About Self</Title>}>
                          {profile.aboutSelf}
                        </Card>{" "}
                        <Card title={<Title level={5}>Partner Requirements</Title>}>
                          {profile.requirements}
                        </Card>{" "}
                      </>
                    ) : null}
                    <div style={{ justify: "center", align: "middle" }}>
                      <Button
                        size="small"
                        type="primary"
                        onClick={() => setShowMore(!showMore)}
                      >
                        {showMore ? "Show less.." : "Show more..."}
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        ) : (
          <Empty
            image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
            imageStyle={{
              height: 120,
            }}
            description={<span>No profile found.</span>}
          ></Empty>
        )}
      </Spin>
    </div>
  );
}

export default Profile;
