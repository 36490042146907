import React from "react";
import Chats from "./layout/chats/Chats";
import Chat from "./layout/chat/Chat";
import { auth } from "../../firebaseConfig";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import { useUserData } from "../../hooks/useUsers";
// import FriendsBar from './layout/friendsBar/FriendsBar';
import { Row, Col, Button, Drawer, Spin } from "antd";
import { MessageTwoTone } from "@ant-design/icons";

import { useAppSelector } from "../../hooks/redux-hooks";

const Messenger = () => {
  let navigate = useNavigate();
  const [user] = useAuthState(auth);
  const { loading, userData } = useUserData(user?.uid);
  const [visible, setVisible] = React.useState(false);
  const chatID = useAppSelector((state) => state.currentChat.chatID);

  React.useEffect(() => {
    if (loading) return;
    if (userData && !userData.profileCompleted) {
      navigate('/add-profile');
    } else {
      return
    }
  }, [user, userData, loading, navigate]);

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  return (
    <Spin spinning={loading}>
      <div className="container">
        <div className="mobileHidden">
          <Row>
            <Col sm={24} md={16} xl={6}>
              <Chats />
            </Col>
            <Col sm={24} md={16} xl={18}>
              {chatID && <Chat />}
            </Col>
            {/* <Col sm={24} md={16} xl={4}>
          <FriendsBar />
        </Col> */}
          </Row>
        </div>
        <div className="mobileVisible">
          <Row>
            <Button type="primary" onClick={showDrawer}>
              <MessageTwoTone />
            </Button>
            <Drawer placement="left" onClose={onClose} open={visible}>
              <Col sm={24}>
                <Chats />
              </Col>
            </Drawer>
            <Col sm={24} md={16} xl={18}>
              {chatID && <Chat />}
            </Col>
          </Row>
        </div>
      </div>
    </Spin>

  );
};

export default Messenger;
