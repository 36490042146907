import React, { useState, useContext, useEffect, useRef } from "react";
import {
  createUserWithEmailAndPassword,
  onAuthStateChanged,
  sendEmailVerification,
  updateProfile,
} from "firebase/auth";
import { doc, setDoc, serverTimestamp } from "firebase/firestore";
import { auth, db } from "../../firebaseConfig";
import { useNavigate, Link } from "react-router-dom";
import { Flex, Row, Col, Spin } from "antd";
// import dayjs from "dayjs";
import { Card, Form, Input, Button, message, Typography } from "antd";
// import {
//   getDateOfBirth,
//   getDomain,
//   getLastName,
//   userName,
//   getRandomValue,
//   getGirlName,
// } from "../../lib/utils";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import OAuth from "./OAuth";
import UserContext from "../../contexts/UserContext";
// import { v4 as uuidv4 } from "uuid";

export default function AdminRegister() {
  const [form] = Form.useForm();
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    password: "",
    userRole: "USER",
    profileCompleted: false,
  });
  const { fullName, email, password, userRole } = formData;
  const { dispatch } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const isMounted = useRef(true);
  // const [imageUrl, setImageUrl] = useState("");

  const { Title } = Typography;

  // const getUrl = async () => {
  //   const response = await fetch(
  //     "https://source.unsplash.com/random/1920x1080/?female-profile"
  //   );
  //   setImageUrl(await response.url);
  // };

  useEffect(() => {
    if (isMounted) {
      onAuthStateChanged(auth, (user) => {
        if (user) {
          <Spin />;
          navigate("/");
        }
      });
    }

    // getUrl();

    return () => {
      isMounted.current = false;
    };
  }, [navigate, isMounted]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // const seedUsers = (e) => {
  //   e.preventDefault();
  //   const defaultAvatar =
  //     "https://firebasestorage.googleapis.com/v0/b/social-media-c7b8a.appspot.com/o/defaultAvatar.png?alt=media&token=f2894209-9a04-4845-b464-fa93cad22a5a";
  //   const defaultCoverImage =
  //     "https://firebasestorage.googleapis.com/v0/b/social-media-c7b8a.appspot.com/o/defaultCover.png?alt=media&token=b28e10b3-f4ae-4dd9-b066-c1053c6182e0";

  //   let gender = "Male";
  //   let firstName = getBoyName();
  //   let lastName = getLastName();

  //   let email = firstName + "." + lastName + "@" + getDomain();
  //   let dateOfBirth = dayjs("12/03/1985").toDate();
  //   let imageUrls = [{ uid: uuidv4(), url: imageUrl }];

  //   let password = "Secret@123";
  //   let fathersName = "John";
  //   let mothersName = "Jane";

  //   createUserWithEmailAndPassword(auth, email, password)
  //     .then(async ({ user }) => {
  //       updateProfile(auth.currentUser, {
  //         displayName: firstName + " " + lastName,
  //       });
  //       const usersRef = doc(db, "users", user.uid);
  //       const userChatsRef = doc(db, "userChats", user.uid);
  //       await setDoc(usersRef, {
  //         uid: user.uid,
  //         userRole: "USER",
  //         dailyLikes: 0,
  //         profileId: uuidv4(),
  //         email: email,
  //         firstName: firstName,
  //         lastName: lastName,
  //         gender: gender,
  //         maritalStatus: getRandomValue("maritals").value,
  //         haveKids: "No",
  //         kids: 0,
  //         kidsLivingStatus: "",
  //         currentResidence: "Testing",
  //         citizenOf: getRandomValue("currencies").value,
  //         motherTongue: getRandomValue("languages").value,
  //         spokenLanguages: ["Hindi", "English"],

  //         subCaste: getRandomValue("subcaste").value,
  //         nakshatram: getRandomValue("nakshatram").value,
  //         gothram: getRandomValue("gothram").value,
  //         padam: getPadam(formData.padam) ? formData.padam : 0,
  //         raasi: getRandomValue("raasis").value,
  //         placeOfBirth: "Testing Place",
  //         dateOfBirth: dateOfBirth,
  //         timeOfBirth: "",
  //         dosham: "No",
  //         doshamType: "",

  //         education: getRandomValue("education").value,
  //         educationDetails: getRandomValue("educationDetails").value,
  //         additionalEducationInfo: "",
  //         employment: getRandomValue("employment").value,
  //         occupation: getRandomValue("occupation").value,
  //         additionalEmploymentInfo: "",
  //         currency: getRandomValue("currencies").value,
  //         salary: Math.floor(Math.random() * 1000000),
  //         salaryType: getRandomValue("salaryType").value,

  //         smoking: getRandomValue("employment").value,
  //         tobacco: getRandomValue("employment").value,
  //         drinking: getRandomValue("employment").value,
  //         drugs: getRandomValue("employment").value,
  //         diet: getRandomValue("employment").value,
  //         height: getRandomValue("employment").value,
  //         weight: getRandomValue("employment").value,
  //         weightType: getRandomValue("employment").value,
  //         bodyType: getRandomValue("employment").value,
  //         disability: getRandomValue("employment").value,

  //         familyValues: getRandomValue("employment").value,
  //         familyType: getRandomValue("employment").value,
  //         familyStatus: getRandomValue("employment").value,
  //         fathersName: fathersName,
  //         fathersOccupation: getRandomValue("employment").value,
  //         mothersName: mothersName,
  //         mothersOccupation: getRandomValue("employment").value,
  //         brothers: 0,
  //         brothersMarital: 0,
  //         sisters: 0,
  //         sistersMarital: 0,
  //         nativePlace: "",

  //         friends: [],
  //         aboutSelf: "",
  //         requirements: "",
  //         profileImages: imageUrls,
  //         createdAt: Timestamp.now().toDate(),
  //         createdBy: user.displayName,
  //         userId: user.uid,
  //         username: userName(gender),
  //         profileCompleted: true,

  //         avatar: defaultAvatar,
  //         coverImage: defaultCoverImage,
  //       });
  //       await setDoc(userChatsRef, {});
  //       auth.signOut();
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  const handleSignup = async () => {
    setLoading(true);
    try {
      const userCredentials = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredentials.user;
      const docRef = doc(db, "users", user.uid);
      const userChatsRef = doc(db, "userChats", user.uid);

      updateProfile(auth.currentUser, { displayName: fullName });
      const formDataCopy = { ...formData };
      delete formDataCopy.password;
      formDataCopy.timestamp = serverTimestamp();
      formDataCopy.uid = user.uid;
      formDataCopy.userRole = userRole;
      dispatch({ type: "SET_USER", payload: auth.currentUser.displayName });
      await setDoc(docRef, formDataCopy);
      await setDoc(userChatsRef, {});
      sendEmailVerification(auth.currentUser);
      auth.signOut();
      message.success("Please confirm your email address");
      navigate("/");
    } catch (error) {
      form.resetFields();
      message.error(error.code);
      setLoading(false);
    }
  };
  return (
    <div className="container">
      <Row
        gutter={[16, 16]}
        type="flex"
        justify="center"
        align="middle"
        style={{ minHeight: "60vh", marginBottom: 8 }}
      >
        <Col className="gutter-row" xs={24} lg={16} xl={10}>
          <Card
            justify="center"
            align="middle"
            style={{
              width: 400,
              margin: "20px",
              borderRadius: "20px",
              overflow: "hidden",
              boxShadow: "5px 8px 24px 5px rgba(208, 216, 243, 0.6)",
            }}
          >
            <Form
              form={form}
              className="login-form"
              autoComplete="off"
              layout="vertical"
              onFinish={handleSignup}
            >
              <Flex
                type="flex"
                justify="center"
                align="middle"
                gap="small"
                wrap="center"
              >
                <Title level={3}>Register Account</Title>
              </Flex>
              <Flex
                type="flex"
                justify="center"
                align="middle"
                gap="small"
                wrap="center"
              >
                <Link to="/login">Already have an Account</Link>
              </Flex>
              <br />
              <Form.Item
                name="fullName"
                rules={[
                  {
                    required: true,
                    message: "Please input Name",
                  },
                ]}
              >
                <Input
                  prefix={<UserOutlined />}
                  placeholder="Name"
                  name="fullName"
                  onChange={handleChange}
                />
              </Form.Item>
              <Form.Item
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Please input Email Address",
                  },
                ]}
              >
                <Input
                  prefix={<UserOutlined />}
                  placeholder="Email address"
                  name="email"
                  onChange={handleChange}
                />
              </Form.Item>
              <Form.Item
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Please input Password",
                  },
                  {
                    min: 8,
                    message: "Password must have a minimum length of 8",
                  },
                  {
                    pattern: new RegExp(
                      "^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*;?&])[A-Za-z\\d@$!%*;?&]{8,}$"
                    ),
                    message:
                      "Password must contain at least one lowercase letter, uppercase letter, number, and special character",
                  },
                ]}
              >
                <Input
                  prefix={<LockOutlined />}
                  type="password"
                  name="password"
                  placeholder="Password"
                  onChange={handleChange}
                />
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="login-form-button"
                  loading={loading}
                >
                  Register
                </Button>
              </Form.Item>
              {/* <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="login-form-button"
                  loading={loading}
                  onClick={() => seedUsers()}
                >
                  Seed User
                </Button>
              </Form.Item> */}
              <OAuth />
            </Form>
          </Card>
        </Col>
      </Row>
    </div>
  );
}
