import React, { useState } from "react";
import { Timestamp, collection, addDoc } from "firebase/firestore";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { storage, db, auth } from "../../firebaseConfig";
import { toast } from "react-toastify";
import { useAuthState } from "react-firebase-hooks/auth";
import { Link } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { readingTime } from "../../lib/utils";
import { Flex, Form, Input, Button } from "antd";
import TagInput from 'antd-tag-input';
import 'antd-tag-input/dist/style.css';

export default function AddBlog() {
  const [user] = useAuthState(auth);
  const [tags, setTags] = useState([]);
  const [description, setDescription] = useState("");
  const [formData, setFormData] = useState({
    title: "",
    content: "",
    category: "",
    image: "",
    createdAt: Timestamp.now().toDate(),
  });

  const [progress, setProgress] = useState(0);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleImageChange = (e) => {
    setFormData({ ...formData, image: e.target.files[0] });
  };

 

  const formInvalid = () => {
    if (!formData.title || !description) {
      return true;
    }
  };

  const handlePublish = () => {
    const storageRef = ref(
      storage,
      `/images/${Date.now()}${formData.image.name}`
    );

    const uploadImage = uploadBytesResumable(storageRef, formData.image);

    uploadImage.on(
      "state_changed",
      (snapshot) => {
        const progressPercent = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setProgress(progressPercent);
      },
      (err) => {
      },
      () => {
        setFormData({
          title: "",
          description: "",
          image: "",
        });

        getDownloadURL(uploadImage.snapshot.ref).then((url) => {
          const articleRef = collection(db, "Articles");
          addDoc(articleRef, {
            title: formData.title,
            description: description,
            category: formData.category,
            tags: tags,
            imageUrl: url,
            createdAt: Timestamp.now().toDate(),
            createdBy: user.displayName,
            userId: user.uid,
            readingTime: readingTime(description),
            likes: [],
            comments: [],
          })
            .then(() => {
              toast("Article added successfully", { type: "success" });
              setProgress(0);

            })
            .catch((err) => {
              toast("Error adding article", { type: "error" });
            });
        });
      }
    );
  };

  return (
    <div className="container">
      {!user ? (
        <>
          <h2>
            <Link to="/signin">Login to create article</Link>
          </h2>
          Don't have an account? <Link to="/register">Signup</Link>
        </>
      ) : (
        <>
          <h2>Create article</h2>
          <Form name="basic" autoComplete="off" layout="vertical">
            <Form.Item
              label="Title"
              name="title"
              rules={[
                {
                  required: true,
                  message: "Please input a title!",
                },
              ]}
            >
              <Input
                name="title"
                value={formData.title}
                onChange={handleChange}
              />
            </Form.Item>
            <Flex vertical gap={30}>
              <Form.Item
                label="Description"
                name="description"
                rules={[
                  {
                    required: true,
                    message: "Please enter description at least 100 words!",
                  },
                ]}
              >
                <ReactQuill
                  theme="snow"
                  name="description"
                  style={{ height: "300px" }}
                  value={description}
                  onChange={setDescription}
                />
              </Form.Item>
              <Form.Item
                label="Categories"
                name="category"
                rules={[
                  {
                    required: true,
                    message: "Please enter categories!",
                  },
                ]}
              >
                <TagInput
                  value={tags}
                  placeholder="please fill in..."
                  onChange={(strArr) => setTags(strArr)}
                />
              </Form.Item>
            </Flex>
            <Form.Item
              label="Images"
              name="image"
              rules={[
                {
                  required: true,
                  message: "Please upload one image!",
                },
              ]}
            >

              <input
                type="file"
                name="image"
                accept="image/*"
                onChange={(e) => handleImageChange(e)}
              />
            </Form.Item>
            {progress === 0 ? null : (
              <div className="progress">
                <div
                  className="progress-bar progress-bar-striped mt-2"
                  style={{ width: `${progress}%` }}
                >
                  {`uploading image ${progress}%`}
                </div>
              </div>
            )}
            <Button onClick={handlePublish} disabled={formInvalid()}>
              Publish
            </Button>
          </Form>
        </>
      )}
    </div>
  );
}
