import React from 'react';
import { Avatar, List } from 'antd';
import { Link } from 'react-router-dom';
import { useAppDispatch } from '../../../../hooks/redux-hooks';
import { useUserData } from '../../../../hooks/useUsers';
import { setCurrentChat } from '../../../../redux/currentChat/slice';

const Chat = ({ chat }) => {

  const { userData } = useUserData(chat[1].userInfo.id);
  // const userID = useAppSelector((state) => state.currentChat.userID);
  const dispatch = useAppDispatch();

  function handleSelectChat() {
    dispatch(setCurrentChat(chat[1].userInfo.id));
  }
  return (
    <div
      style={{
        height: "40rem",
        overflow: 'auto',
        padding: '0 16px',
        border: '1px solid rgba(140, 140, 140, 0.35)',
      }}>
      <List>
        <Link onClick={handleSelectChat}>
          <List.Item>
            <List.Item.Meta
              avatar={<Avatar src={`https://api.dicebear.com/7.x/miniavs/svg?seed=1`} />}
              title={`${userData?.firstName} ${userData?.lastName}`}
              description={chat[1].lastMessage?.text}
            />
          </List.Item>
        </Link>
      </List>
    </div>
  );
};

export default Chat;
