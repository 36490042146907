import jsonData from "../data/gunas.json";
import jsonValues from "../data/data.json";

function readingTime(article) {
  const text = article;
  const wpm = 225;
  const words = text.trim().split(/\s+/).length;
  const time = Math.ceil(words / wpm);
  return time + " mins";
}

/* Nice function from https://stackoverflow.com/questions/73159795/how-can-i-calculate-age-from-date-of-birth-react-js */
function getAge(dateString) {
  var today = new Date();
  var birthDate = new Date(dateString.toDate());
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
}

function getDays(dateString) {
  const newStartDate= new Date();
  const newEndDate = new Date(dateString.toDate());
  const one_day = 1000*60*60*24;

  let result = Math.ceil((newEndDate.getTime()-newStartDate.getTime())/(one_day))

  if (result < 0 ) {return 0}
    return result

}

function userName(gender) {
  const userNamePrefix = gender === "Male" ? "G" : "B";
  return userNamePrefix + Math.random().toString(16).slice(9).toUpperCase();
}

function getGunaPoints(astro) {
  const gunas = jsonData.gunas;
  const guna = gunas
    .filter((guna) => guna.key === astro)
    .map((guna) => guna.value);
  return guna[0];
}

function getPadam(nakshatram) {
  switch (nakshatram) {
    case "Krittika":
      return true;
    case "Mrigasira":
      return true;
    case "Punarvasu":
      return true;
    case "Uttara Phalguni":
      return true;
    case "Chitra":
      return true;
    case "Vishakha":
      return true;
    case "Uttarashada":
      return true;
    case "Dhanishta":
      return true;
    case "Purvabhadra":
      return true;
    default:
      return false;
  }
}

function getRandomValue(text) {
  var random =
    jsonValues[text][Math.floor(Math.random() * jsonValues[text].length)];

  return random;
}

async function getImageUrl() {
  let response = await fetch(
    "https://source.unsplash.com/random/1920x1080/?wallpaper,landscape"
  ).then((data) => data.url);

  console.log(response);
}

function getLastName() {
  let last_name = [
    "Sharma",
    "Pandit",
    "Bhatt",
    "Chaturvedi",
    "Dubey",
    "Iyer",
    "Joshi",
    "Mishra",
    "Pandey",
    "Trivedi",
    "Dwivedi",
    "Kamath",
    "Karanth",
    "Namboothiris",
    "Shenoy",
    "Tiwari",
    "Tripathi",
    "Deshpande",
    "Hegde",
    "Mukherjee",
    "Bhaduri",
    "Bhagavati",
    "Bharadwaj",
    "Abraham",
    "Allan",
    "Alsop",
    "Anderson",
    "Arnold",
    "Avery",
    "Bailey",
    "Baker",
    "Ball",
    "Bell",
    "Berry",
    "Black",
    "Blake",
    "Bond",
    "Bower",
    "Brown",
    "Buckland",
    "Burgess",
    "Butler",
    "Cameron",
    "Campbell",
    "Carr",
    "Chapman",
    "Churchill",
    "Clark",
    "Clarkson",
    "Coleman",
    "Cornish",
    "Davidson",
    "Davies",
    "Dickens",
    "Dowd",
    "Duncan",
    "Dyer",
    "Edmunds",
    "Ellison",
    "Ferguson",
    "Fisher",
    "Forsyth",
    "Fraser",
    "Gibson",
    "Gill",
    "Glover",
    "Graham",
    "Grant",
    "Gray",
    "Greene",
    "Hamilton",
    "Hardacre",
    "Harris",
    "Hart",
    "Hemmings",
    "Henderson",
    "Hill",
    "Hodges",
    "Howard",
    "Hudson",
    "Hughes",
    "Hunter",
    "Ince",
    "Jackson",
    "James",
    "Johnston",
    "Jones",
    "Kelly",
    "Kerr",
    "King",
    "Knox",
    "Lambert",
    "Langdon",
    "Lawrence",
    "Lee",
    "Lewis",
    "Lyman",
    "MacDonald",
    "Mackay",
    "Mackenzie",
    "MacLeod",
    "Manning",
    "Marshall",
    "Martin",
    "Mathis",
    "May",
    "McDonald",
    "McLean",
    "McGrath",
    "Metcalfe",
    "Miller",
    "Mills",
    "Mitchell",
    "Morgan",
    "Morrison",
    "Murray",
    "Nash",
    "Newman",
    "Nolan",
    "North",
    "Ogden",
    "Oliver",
    "Paige",
    "Parr",
    "Parsons",
    "Paterson",
    "Payne",
    "Peake",
    "Peters",
    "Piper",
    "Poole",
    "Powell",
    "Pullman",
    "Quinn",
    "Rampling",
    "Randall",
    "Rees",
    "Reid",
    "Roberts",
    "Robertson",
    "Ross",
    "Russell",
    "Rutherford",
    "Sanderson",
    "Scott",
    "Sharp",
    "Short",
    "Simpson",
    "Skinner",
    "Slater",
    "Smith",
    "Springer",
    "Stewart",
    "Sutherland",
    "Taylor",
    "Terry",
    "Thomson",
    "Tucker",
    "Turner",
    "Underwood",
    "Vance",
    "Vaughan",
    "Walker",
    "Wallace",
    "Walsh",
    "Watson",
    "Welch",
    "White",
    "Wilkins",
    "Wilson",
    "Wright",
    "Young"
  ];

  return last_name[Math.floor(Math.random() * last_name.length)];
}

function getBoyName() {
  let boy_name = [
    "Aarav",
    "Abhik",
    "Aditya",
    "Adyah",
    "Akshar",
    "Ambareesh",
    "Anagh",
    "Anshumat",
    "Ritwik",
    "Rehaan",
    "Rudr",
    "Vaman",
    "Vibhu",
    "Viraja",
    "Vishnu",
    "Vishwanath",
    "Vyas",
    "Sanu",
    "Shantanu",
    "Shivaya",
    "Rakshit",
    "Rushabh",
    "Partha",
    "Nahush",
    "Nilkanth",
    "Nimit",
    "Nishit",
    "Mohit",
    "Kashyap",
    "Karun",
    "Jatin",
    "Jiva",
    "Ishan",
    "Ijya",
    "Iravan",
    "Hemant",
    "Dhurya",
    "Dharesh",
    "Dhruv",
    "Dhurya",
    "Vansh",
    "Vihaan",
    "Varun",
    "Hrihaan",
    "Rishi",
    "Samrat",
    "Parth",
    "Aadi",
    "Neil",
    "Siddharth",
    "Siddhesh",
    "Gauraang",
    "Adam",
    "Adrian",
    "Alan",
    "Alexander",
    "Andrew",
    "Anthony",
    "Austin",
    "Benjamin",
    "Blake",
    "Boris",
    "Brandon",
    "Brian",
    "Cameron",
    "Carl",
    "Charles",
    "Christian",
    "Christopher",
    "Colin",
    "Connor",
    "Dan",
    "David",
    "Dominic",
    "Dylan",
    "Edward",
    "Eric",
    "Evan",
    "Frank",
    "Gavin",
    "Gordon",
    "Harry",
    "Ian",
    "Isaac",
    "Jack",
    "Jacob",
    "Jake",
    "James",
    "Jason",
    "Joe",
    "John",
    "Jonathan",
    "Joseph",
    "Joshua",
    "Julian",
    "Justin",
    "Keith",
    "Kevin",
    "Leonard",
    "Liam",
    "Lucas",
    "Luke",
    "Matt",
    "Max",
    "Michael",
    "Nathan",
    "Neil",
    "Nicholas",
    "Oliver",
    "Owen",
    "Paul",
    "Peter",
    "Phil",
    "Piers",
    "Richard",
    "Robert",
    "Ryan",
    "Sam",
    "Sean",
    "Sebastian",
    "Simon",
    "Stephen",
    "Steven",
    "Stewart",
    "Thomas",
    "Tim",
    "Trevor",
    "Victor",
    "Warren",
    "William"
  ];

  return boy_name[Math.floor(Math.random() * boy_name.length)];
}

function getGirlName() {
  let girl_name = [
    "Aadya",
    "Aastha",
    "Aditi",
    "Ati",
    "Dhriti",
    "Dipta",
    "Diva",
    "Harini",
    "Irya",
    "Ishani",
    "Jeevitha",
    "Madhvi",
    "Mahi",
    "Mahitha",
    "Mekhala",
    "Naima",
    "Nanda",
    "Nimi",
    "Nirja",
    "Nishi",
    "Nitha",
    "Nivritti",
    "Pari",
    "Pradha",
    "Pradnya",
    "Pragya",
    "Pratjna",
    "Pratishtha",
    "Preet",
    "Pushti",
    "Risha",
    "Ruhi",
    "Rukma",
    "Sadhana",
    "Shambhavi",
    "Shubha",
    "Shuchi",
    "Subhadra",
    "Sudiksha",
    "Supreet",
    "Swadha",
    "SWasti",
    "Tripti",
    "Tushti",
    "Uditi",
    "Urvi",
    "Varenya",
    "Vibha",
    "Fiona",
    "Gabrielle",
    "Grace",
    "Hannah",
    "Heather",
    "Irene",
    "Jan",
    "Jane",
    "Jasmine",
    "Jennifer",
    "Jessica",
    "Joan",
    "Joanne",
    "Julia",
    "Karen",
    "Katherine",
    "Kimberly",
    "Kylie",
    "Lauren",
    "Leah",
    "Lillian",
    "Lily",
    "Lisa",
    "Madeleine",
    "Maria",
    "Mary",
    "Megan",
    "Melanie",
    "Michelle",
    "Molly",
    "Natalie",
    "Nicola",
    "Olivia",
    "Penelope",
    "Pippa",
    "Rachel",
    "Rebecca",
    "Rose",
    "Ruth",
    "Sally",
    "Samantha",
    "Sarah",
    "Sonia",
    "Sophie",
    "Stephanie",
    "Sue",
    "Theresa",
    "Tracey",
    "Una",
    "Vanessa",
    "Victoria",
    "Virginia",
    "Wanda",
    "Wendy",
    "Yvonne",
    "Zoe"
  ];

  return girl_name[Math.floor(Math.random() * girl_name.length)];
}

function getDomain() {
  let domains = [
    "gmail.com",
    "example.com",
    "faker.com",
    "mail.com",
    "yahoo.com",
    "hotmail.com",
    "icloud.com",
    "outlook.com",
  ];

  return domains[Math.floor(Math.random() * domains.length)];
}

function getDateOfBirth() {
  const startDate = new Date("1960-01-01");
  const endDate = new Date("2005-12-31");
  const timeDiff = endDate.getTime() - startDate.getTime();
  const randomTime = Math.random() * timeDiff;
  const randomDate = new Date(startDate.getTime() + randomTime);

  return new Intl.DateTimeFormat("en-US", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit"
  }).format(randomDate)
}

export {
  readingTime,
  getAge,
  getDays,
  userName,
  getGunaPoints,
  getImageUrl,
  getPadam,
  getRandomValue,
  getLastName,
  getBoyName,
  getGirlName,
  getDomain,
  getDateOfBirth,
};
