import React from 'react';

import image1 from '../assets/images/community.jpg';
import image2 from '../assets/images/filters.jpg';
import image3 from '../assets/images/horoscope.jpg';
import image4 from '../assets/images/mobile.jpg';
import image5 from '../assets/images/blog.jpg';
import image6 from '../assets/images/advanced-option.jpg';

import { Row, Col } from 'antd';
import { Card } from 'antd';
const { Meta } = Card;

function AppFeature() {
  return (
    <div id="feature" className="block featureBlock bgGray">
      <div className="container-fluid">
        <div className="titleHolder">
          <h2>Key Features and Benefits</h2>
        </div>
        <Row gutter={[16, 16]}>
          <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }}>
            <Card
              hoverable
              cover={<div style={{ overflow: "hidden", height: "240px" }}>
              <img
                alt="Community Specific Profiles"
                style={{ height: "100%", width: "100%" }}
                src={image1}
              />
            </div>}
            >
              <Meta title="Community-specific Profiles" description="Dedicated profiles for Brahmin individuals, allowing users to specify their subcaste, family background, and other relevant details" />
            </Card>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }}>
            <Card
              hoverable
              cover={<div style={{ overflow: "hidden", height: "240px" }}>
                <img
                  alt="Subcaste Filters"
                  style={{ height: "100%", width: "100%" }}
                  src={image2}
                />
              </div>}
            >
              <Meta title="Subcaste Filters" description="Advanced search filters that enable users to narrow down their search based on specific subcastes within the Brahmin community." />
            </Card>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }}>
            <Card
              hoverable
              cover={<div style={{ overflow: "hidden", height: "240px" }}>
              <img
                alt="Horoscope Matching"
                style={{ height: "100%", width: "100%" }}
                src={image3}
              />
            </div>}
            >
              <Meta title="Horoscope Matching" description="Integrated horoscope matching tools, providing singles with the option to assess astrological compatibility before making a decision" />
            </Card>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }}>
            <Card
              hoverable
              cover={<div style={{ overflow: "hidden", height: "240px" }}>
              <img
                alt="Mobile App Compatibity"
                style={{ height: "100%", width: "100%" }}
                src={image4}
              />
            </div>}
            >
              <Meta title="Mobile App Compatibility" description="Responsive design so it can be easily accessable from their smartphones, ensuring flexibility and ease of use" />
            </Card>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }}>
            <Card
              hoverable
              cover={<div style={{ overflow: "hidden", height: "240px" }}>
              <img
                alt="Community Blogs and Resources"
                style={{ height: "100%", width: "100%" }}
                src={image5}
              />
            </div>}
            >
              <Meta title="Community Blogs and Resources" description="Informative blogs and resources on topics relevant to Brahmin culture, traditions, and marriage, serving as a knowledge-sharing platform" />
            </Card>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }}>
            <Card
              hoverable
              cover={<div style={{ overflow: "hidden", height: "240px" }}>
              <img
                alt="Responsive Support"
                style={{ height: "100%", width: "100%" }}
                src={image6}
              />
            </div>}
            >
              <Meta title="Responsive Support" description="Responsive support services that understand the unique needs and concerns of Brahmin users, providing assistance when needed" />
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default AppFeature;