import { doc, onSnapshot } from 'firebase/firestore';
import React from "react"
import { auth, db } from '../firebaseConfig';

export const useChats = () => {
  const [chats, setChats] = React.useState()

  React.useEffect(() => {
    async function getChats() {
      if (auth.currentUser?.uid) {
        onSnapshot(doc(db, 'userChats', auth.currentUser?.uid), (doc) => {
          setChats(doc.data())
        })
      }
    }
    getChats();
  }, []);

  if (chats) return Object.entries(chats)

}