import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Hero from './hero';
import AppAbout from './about';
import AppFeature from './feature';
import AppFAQ from './faq';

function AppHome() {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const mode = queryParams.get("mode");
  const apiKey = queryParams.get("apiKey");
  const actionCode = queryParams.get("oobCode");

  console.log(window.location.href, mode, apiKey, actionCode);

  useEffect(() => {
  if ( mode === "resetPassword") {
    navigate(`/forgot-password?mode=${mode}&oobCode=${actionCode}&apiKey=${apiKey}`);
  } else if ( mode === "verifyEmail") {
    navigate(`/login?mode=${mode}&oobCode=${actionCode}&apiKey=${apiKey}`);
  }
})

  return (
    <div className='container'>
      <Hero />
      <AppAbout />
      <AppFeature />
      <AppFAQ />
    </div>
  );
}

export default AppHome;