import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Row, Col, Form, Input, Button } from "antd";
import { useAuthState } from "react-firebase-hooks/auth";
import { sendPasswordResetEmail, confirmPasswordReset } from "firebase/auth";
import { auth } from "../../firebaseConfig";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import "./auth.css";
import { Flex, Card, Typography, message } from "antd";
import renderIf from "render-if";

export default function PasswordReset() {
  const [form] = Form.useForm();
  const [email, setEmail] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const navigate = useNavigate();
  const [user, loading] = useAuthState(auth);
  const { Title } = Typography;

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const mode = queryParams.get("mode");
  const apiKey = queryParams.get("apiKey");
  const oobCode = queryParams.get("oobCode");

  console.log(queryParams, mode, apiKey, oobCode, mode === "resetPassword");

  useEffect(() => {
    if (loading) return;
    if (user) navigate("/");
  }, [user, loading, navigate]);

  const handlePasswordReset = async () => {
    if (mode === "resetPassword") {
      if (apiKey === process.env.REACT_APP_API_FIREBASE_KEY) {
        try {
          await confirmPasswordReset(auth, oobCode, newPassword);
          form.resetFields();
          message.success("Password updated successfully");
          navigate("/login");
        } catch (error) {
          switch (error.code) {
            case "auth/invalid-action-code":
              message.error("Reset Password link invalid or expired ");
              break;
            case "auth/wrong-password":
              break;
            default:
              return;
          }
        }
      } else {
        message.error("Invalid url or invalid key");
      }
    } else {
      try {
        await sendPasswordResetEmail(auth, email);
        form.resetFields();
        message.success(
          "If your email is in our system, you will receive instructions to reset your password shortly."
        );
      } catch (error) {
        console.log(error);
        message.error("Unexpected error", error);
      }
    }
  };

  return (
    <div className="container">
      <Row
        gutter={[16, 16]}
        type="flex"
        justify="center"
        align="middle"
        style={{ minHeight: "60vh", marginBottom: 8 }}
      >
        <Col className="gutter-row" xs={24} lg={16} xl={10}>
          <Card
            justify="center"
            align="middle"
            style={{
              width: 400,
              margin: "20px",
              borderRadius: "20px",
              overflow: "hidden",
              boxShadow: "5px 8px 24px 5px rgba(208, 216, 243, 0.6)",
            }}
          >
            <Form
              form={form}
              className="login-form"
              autoComplete="off"
              layout="vertical"
              onFinish={handlePasswordReset}
            >
              <Flex
                type="flex"
                justify="center"
                align="middle"
                gap="small"
                wrap="center"
              >
                <Title level={3}>
                  {mode === "resetPassword"
                    ? "Reset Password"
                    : "Forgot Password"}
                </Title>
              </Flex>
              {console.log(mode === "resetPassword")}
              {renderIf(mode === "resetPassword")(
                <Form.Item
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Please input Password",
                    },
                    {
                      min: 8,
                      message: "Password must have a minimum length of 8",
                    },
                    {
                      pattern: new RegExp(
                        "^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&;:,])[A-Za-z\\d@$!%*?&;:,]{8,}$"
                      ),
                      message:
                        "Password must contain at least one lowercase letter, uppercase letter, number, and special character",
                    },
                  ]}
                >
                  <Input
                    prefix={<LockOutlined />}
                    type="password"
                    name="password"
                    placeholder="Password"
                    onChange={(e) => {
                      setNewPassword(e.target.value);
                    }}
                  />
                </Form.Item>
              )}
              {renderIf(mode === null)(
                <Form.Item
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "Please input Email Address",
                    },
                  ]}
                >
                  <Input
                    prefix={<UserOutlined />}
                    placeholder="Email address"
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                  />
                </Form.Item>
              )}

              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="login-form-button"
                >
                  {mode === "resetPassword"
                    ? "Update Password"
                    : "Send Instructions to reset Password"}
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>
    </div>
  );
}
