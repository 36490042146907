import React from 'react';
import { useChats } from '../../../../hooks/useChats';
import Chat from './Chat';
import { v4 as uuidv4 } from "uuid";
import { Empty } from 'antd';

const Chats = () => {
  const chats = useChats();

  return (
    chats?.length === 0 ?
      <div className="container">
        <div className="heroBlock">
          <div id="feature" className="block featureBlock bgGray">
            <Empty
              image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
              imageStyle={{
                height: 120,
              }}
              description={<span>No messages</span>}
            ></Empty>
          </div>
        </div>
      </div> :
      <div id="feature" className="block featureBlock bgGray">
        <div className="titleHolder">
          <h3>Users</h3>
        </div>
        {chats
          ?.sort((a, b) => b[1].date - a[1].date)
          .map((chat) => {
            return <><Chat key={uuidv4()} chat={chat} /> </>;
          })}
      </div>
  );
};

export default Chats;
