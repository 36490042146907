import { Col, Row } from "antd";
import { Carousel } from "antd";
import {
  UserOutlined,
  SearchOutlined,
  TagOutlined,
  MessageOutlined,
} from "@ant-design/icons";

import image1 from "../assets/images/banner-img1.jpg";
import image2 from "../assets/images/banner-img2.jpg";
import image3 from "../assets/images/banner-img3.jpg";

function Hero() {
  return (
    <div className="heroBlock">
      <Row gutter={[24, 24]}>
        {/* carousel */}
        <Col xs={24} lg={18}>
          <Carousel autoplay autoplaySpeed={5000}>
            <div>
              <img src={image1} alt="banner 1" />
            </div>
            <div>
              <img src={image2} alt="banner 2" />
            </div>
            <div>
              <img src={image3} alt="banner 3" />
            </div>
          </Carousel>
        </Col>
        {/* hero blocks */}
        <Col xs={24} lg={6}>
          <div className="heroBlocks">
            <div className="holder">
              <div className="icon">
                <UserOutlined />
              </div>
              <div className="content">
                <h3>Free Profile Creation</h3>
                <p>
                  Users can create profiles with personal information, photos,
                  and details about their interests, lifestyle
                </p>
              </div>
            </div>
            <div className="holder">
              <div className="icon">
                <SearchOutlined />
              </div>
              <div className="content">
                <h3>Search Feature</h3>
                <p>
                  Search filters to help users find potential matches based on
                  criteria such as age, location, interests
                </p>
              </div>
            </div>
            <div className="holder">
              <div className="icon">
                <TagOutlined />
              </div>
              <div className="content">
                <h3>Send Interest</h3>
                <p>
                  Send interests for free to potential matches. Once your interest is
                  accepted, you can start a conversation.
                </p>
              </div>
            </div>
            <div className="holder">
              <div className="icon">
                <MessageOutlined />
              </div>
              <div className="content">
                <h3>Send messages free</h3>
                <p>
                  Private messaging systems for users to interact with each
                  other.
                </p>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default Hero;
