import { arrayRemove, arrayUnion, doc, updateDoc, setDoc } from 'firebase/firestore';
import { db } from '../firebaseConfig';
import { onSelectChat } from './onSelectChat'

export async function onAcceptFriend(id, userId) {
  const userRef = doc(db, 'users', id);
  const requestedUserRef = doc(db, 'users', userId);
  const userChatsRef = doc(db, "userChats", id);

  await updateDoc(userRef, {
    friendRequests: arrayRemove(userId),
  });
  await updateDoc(userRef, {
    friends: arrayUnion(userId),
  });
  await updateDoc(requestedUserRef, {
    friends: arrayUnion(id),
  });

  // Call onSelectChat
  onSelectChat(userId);

  await setDoc(userChatsRef, {})

}