import React from "react";
// import { useAppSelector } from '../../../../hooks/redux-hooks';
import { useMessages } from "../../../../hooks/useMessages";
// import { useUserData } from '../../../../hooks/useUsers';
import s from "../../Messenger.module.scss";
import WriteMessage from "./WriteMessage";
import Message from "./Message";

const Chat = () => {
  const messages = useMessages();

  return (
    <div id="feature" className="block featureBlock bgGray">
      <div className="titleHolder">
        <h3>Chat</h3>
      </div>
      <div
        style={{
          height: "40rem",
          overflow: "auto",
          padding: "0 16px",
          border: "1px solid rgba(140, 140, 140, 0.35)",
        }}
      >
        <div className={s.chat}>
          {messages?.map((m) => {
            return <Message key={m.id} message={m} />;
          })}
        </div>
      </div>
      <div className={s.messageInput}>
        <WriteMessage />
      </div>
    </div>
  );
};

export default Chat;
