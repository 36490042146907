import React from "react"
import { doc, onSnapshot } from "firebase/firestore"
import { db } from "../firebaseConfig"

export const useUserData = (id) => {
  const [loading, setLoading] = React.useState(true)
  const [userData, setUserData] = React.useState();
 
  React.useEffect(() => {
    async function getUserDataById() {
      if (id) {
        setLoading(true)
        onSnapshot(doc(db, 'users', id), (doc) => {
          setUserData(doc.data())
        })
        setLoading(false)
      }
    }

    getUserDataById();
  }, [id]);

  return { loading, userData };
}