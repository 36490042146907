import React from "react";
import { Link } from "react-router-dom";
import { Divider, Typography, Image, Flex, Tag } from "antd";
import { UserAddOutlined } from "@ant-design/icons";

const { Title, Text } = Typography;
export default function BlogPreview({ article }) {
  return (
    <>
      <Link to={`/blog/${article.id}`}>
        <Title level={2}>{article.title}</Title>
      </Link>
      <Flex vertical gap={20}>
        <Text type="secondary">
          <UserAddOutlined />
          {article?.createdBy} | Date :{" "}
          {article?.createdAt.toDate().toDateString()} | {" "}
          {article.tags?.map( (tag) => (<Tag>{tag}</Tag>))}
        </Text>
        <Image preview={false} src={article.imageUrl} height="400px" />
      </Flex>
      <Text>
        <div
          dangerouslySetInnerHTML={{
            __html: article?.description?.substring(0, 256),
          }}
        />
      </Text>
      <Divider style={{ backgroundColor: "blue" }} />
    </>
  );
}
