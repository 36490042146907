
import { arrayRemove, arrayUnion, doc, updateDoc, Timestamp, increment } from 'firebase/firestore';

import { db } from '../firebaseConfig';

export async function onAddFriend(id, userID, isFriendAdded, isResetLikes) {
  const userRef = doc(db, 'users', id);
  const currentUserRef = doc(db, 'users', userID);

  await updateDoc(userRef, {
    friendRequests: isFriendAdded ? arrayRemove(userID) : arrayUnion(userID)
  });

  await updateDoc(currentUserRef, {
    dailyLikes: isResetLikes ? 1 : increment(1),
    lastUpdated: Timestamp.now().toDate(),
  })
}